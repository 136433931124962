import axios from "axios"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
// let rm = RoomManager

//  定数を判定するために利用します。
export default class Const {
    static year = DateTime.local().year

    /**
     * UserGroup
     */
    static STATUS_BEFORE_CONFIRM(num): boolean {
        return num == 1
    }

    // static STATUS_CONFIRMED(num): boolean {
    //     return num == 100
    // }

    static STATUS_CONFIRMED(): number {
        return 100
    }

    static STATUS_FREE_PLAN() {
        return 1000
    }

    static STATUS_CHUKAI_FREE_PLAN = 2000

    static STATUS_LIGHT_PLAN() {
        return 10000
    }

    static STATUS_BUSINESS_PLAN() {
        return 30000
    }

    static STATUS_ENTERPRISE_PLAN() {
        return 100000
    }

    static readonly CELL_HEIGHT = 18

    static readonly UserGroupRole = {
        member: 1,
        admin: 100,
    }

    // STATUS_FREE_PLAN = 1_000 # 無料プランの利用を開始
    // STATUS_ENTERPRISE_PLAN = 2_000 # エンタープライズプランの利用を開始
    static started(rm: any): boolean {
        Logger(`Const#started rm.tutorial_phase:${rm?.tutorial_phase}, rm.group_status:${rm?.userInfo?.group_status}`)
        if (rm.tutorial_phase >= 20) {
            if (rm?.userInfo?.group_status >= this.STATUS_LIGHT_PLAN()) {
                return true
            }
            if (rm?.userInfo?.group_status == Const.STATUS_CHUKAI_FREE_PLAN) {
                return true
            }
        }

        return false
        // return tutorial_phase == 100
    }

    /**
     * 仲介会社によって招待されたアカウントかどうかを判定します。
     * @param rm
     * @returns
     */
    static chukaiFreePlan(rm: any): boolean {
        return rm?.userInfo?.group_status == Const.STATUS_CHUKAI_FREE_PLAN
    }

    // ビジネスプラン以上の契約かを判定.
    static paidOverBussiness(rm: any): boolean {
        return rm.tutorial_phase >= 19 && rm.userInfo.group_status >= this.STATUS_BUSINESS_PLAN()
    }

    /**
     * ビジネスプラン以上だったら、指定したパスに遷移
     * それ以外の場合、指定したモーダルを表示します。
     * @param rm
     * @param successPath
     * @param failModalName
     *
     */
    static paidWithPath(rm: any, successPath: string, failModalName: string, _this: any): void {
        if (Const.paidOverBussiness(rm)) {
            _this.$router.push(successPath)
        } else {
            // ビジネスプラン以上でご利用いただけます。
            _this.$vfm.open(failModalName)
        }
    }

    // STATUS_SUSPEND = 1_100 利用停止中.
    static suspend(rm: any): boolean {
        if (!rm || !rm.userInfo || !rm.userInfo.group_status) return false
        return rm.userInfo.group_status == 1100
    }

    /**
     * メッセージに一度にアップロードできるファイルの数.
     */
    static uploadFileLimit() {
        return 5
    }

    /**
     * ファイルアップロードできるサイズ上限.
     */
    static uploadFileSizeLimit(from: string) {
        if (from == `public_image`) {
            return 5000000
        } else if (from == `chat`) {
            return 200000000
        } else if (from == `file_import`) {
            return 1000000
        }
    }

    /**
     * ファイルアップロードできるサイズ上限を超えた場合のアラート.
     */
    static uploadFileSizeLimitText(from: string) {
        if (from == `public_image`) {
            return `ファイルのサイズ上限は5MBです。`
        } else if (from == `chat`) {
            return `ファイルのサイズ上限は200MBです。`
        } else if (from == `file_import`) {
            return `ファイルのサイズ上限は1MBです。`
        }
    }

    static readonly spWidth = 1000
    static readonly zoomConnectAccount = `Zoom(アカウント連携)`
    static readonly teamsConnectAccount = `Teams(アカウント連携)`

    // SpanSlotで利用する分換算した最小値（0分）
    static readonly minDayMins = 0

    // SpanSlotで利用する分換算した最大値（100日）
    static readonly maxDayMins = 144_000
}
