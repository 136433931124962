
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagEvent } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import UserFile from "packs/models/UserFile"
import FileUtil from "packs/utils/FileUtil"

@Options({
    components: { VueFinalModal },
})
export default class ImagePreviewModal extends Vue {
    @Prop()
    file: UserFile

    // data:
    mounted() {}

    dismiss() {
        this.$vfm.close("ImagePreviewModal")
    }

    openModal() {
        gtagEvent(`show`, `画像プレビューモーダル`, `${this.file ? this.file.original_filename : ``}`)
    }

    // @Emit("fadeOutAdModal")
    fadeOutAdModal() {
        gtagClick(`画像プレビューモーダル:閉じる`, this.file.original_filename)
        return
    }

    /**
     * ファイルをダウンロード.
     */
    downloadFile() {
        gtagClick(`画像プレビューモーダル:ダウンロード`, this.file.original_filename)
        FileUtil.downloadFile(this.file)
    }
}
