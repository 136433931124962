import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger } from "../common"
import RoomMember from "./RoomMember"
import Appointment from "./Appointment"
import DateTag from "./DateTag"
import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "./Notice"
import Const from "packs/utils/Const"
import { gtagEvent } from "packs/GoogleTagManager"
import NotificationControl from "packs/utils/NotificationControl"
import lodash from "lodash"
import FileUtil from "packs/utils/FileUtil"

export default class UserFile {
    constructor(
        public file_id: string,
        public original_filename: string,
        public file_size: string,
        public file_size_byte: number,
        public can_delete: boolean,
        public content_type: string,
        public created_at: number,
        public did_delete: boolean,
        public extname: string,
        public image_url: string,
        public is_image_file: boolean,
        public status: string,
        public public_room_id: string,
        public timing_type: string
    ) {}

    static fetchFromJson(filesJson: any[]): UserFile[] {
        let files = []
        for (let _file of filesJson || []) {
            let file: UserFile = _file
            files.push(file)
        }
        return files
    }

    static copy(o: UserFile): UserFile {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        return clone
    }

    static uploadFile(params, pathType: string = `chat`) {
        let path = `upload_file_in_chat_room`
        if (pathType == `form`) {
            path = `upload_file_from_form`
        } else if (pathType == `group`) {
            path = `upload_file_from_group`
        }

        return axios
            .post(`${Util.prefixUrl}/file_uploaders/${path}`, params)
            .then(res => {
                // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data
            })
            .catch(err => {
                Logger(`err.message:${err.message}, response:${Util.output(err.response)}, status:${err.response.status}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * ストレージにアップロードしたファイルを削除します。
     * @param fileId
     */
    static delete(fileId: string, parentId: string = null) {
        let params = { file_id: fileId, parent_id: parentId }

        return axios
            .delete(`${Util.prefixUrl}/file_uploaders/delete_file`, { data: params })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`

                return true
            })
            .catch(err => {
                Logger(`err.message:${err.message}, response:${Util.output(err.response)}, status:${err.response.status}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * 最初の1つのファイルのみをパラメータ化して返します。
     * @param event
     * @param from [string] chat / public_image / file_import
     * @param allowMulti [boolean] 複数ファイル選択を許すか. 許さない場合は、1つ目のファイルのみを返します。
     */
    static createFileParams(event, from = `chat`, allowMulti = false): FormData[] {
        let fileList = event.target || {}
        Logger(`fileList: ${Util.output(fileList)}`)

        if (Util.isBlank(fileList.files)) {
            Logger(`dataTransferをつめこみます: ${event.dataTransfer.files.length}`)
            fileList = event.dataTransfer //
        }

        // ファイルが無い時は処理を中止
        if (!fileList.files || fileList.files.length == 0) {
            return null
        }
        // let files = [...fileList.files]
        const files = fileList.files

        let forms = []

        if (files && files.length > 0) {
            for (let _file of files) {
                if (!_file) continue
                gtagEvent(`${from}/ファイル添付`, `${_file.type}`, `${_file.name} ${_file.size}`)
                if (_file.size > Const.uploadFileSizeLimit(from)) {
                    gtagEvent(`添付ファイルサイズ上限`, `${_file.type}`, `${_file.size}`)
                    Notice.message = Const.uploadFileSizeLimitText(from)
                    continue
                }

                let params = new FormData()
                params.append("file", _file)
                forms.push(params)

                if (!allowMulti && forms.length > 0) break
            }
        }
        return forms
    }

    // file_id: string,
    // public original_filename: string,
    // public file_size: string,
    // public can_delete: boolean,
    // public content_type: string,
    // public created_at: number,
    // public did_delete: boolean,
    // public extname: string,
    // public image_url: string,
    // public is_image_file: boolean
    static createSampleFiles(): UserFile[] {
        let files = []
        for (let _num of Array(22)
            .fill(0)
            .map((_, i) => i + 1)) {
            let _file = new UserFile(
                `${_num}`,
                `${_num}`,
                ``,
                null,
                false,
                `jpg`,
                null,
                false,
                ``,
                `/assets/header_samples/${_num}`,
                true,
                null,
                null,
                null
            )
            files.push(_file)
        }
        return files
    }

    static saveLogoUrl(url: string) {
        let params = { logo_url: url }
        return axios
            .put(`${Util.prefixUrl}/user_groups/logo`, params)
            .then(res => {
                // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data
            })
            .catch(err => {
                Logger(`err.message:${err.message}, response:${Util.output(err.response)}, status:${err.response.status}`)
                NotificationControl.showErrorMessage(err)

                return null
            })
    }

    static getDownloadableFiles() {
        return axios
            .get(`${Util.prefixUrl}/download_rooms/downloadable_files`)
            .then(res => {
                // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data
            })
            .catch(err => {
                Logger(`err.message:${err.message}, response:${Util.output(err.response)}, status:${err.response.status}`)
                NotificationControl.showErrorMessage(err)

                return null
            })
    }

    /**
     * ストレージにアップロードしたファイルを削除します。
     * @param fileId
     */
    static deleteDownloadableFile(fileId: string) {
        let params = { file_id: fileId }

        return axios
            .delete(`${Util.prefixUrl}/download_rooms/delete_file`, { data: params })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`

                return true
            })
            .catch(err => {
                Logger(`err.message:${err.message}, response:${Util.output(err.response)}, status:${err.response.status}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    static downloadDownloadableFile(uf: UserFile) {
        let params = { file_id: uf.file_id }

        return axios
            .request({ url: `${Util.prefixUrl}/download_rooms/download_file?`, responseType: "blob", params: params })
            .then(res => res.data)
            .then(blob => {
                let fileType = uf.extname == `csv` ? `csv` : `xlsx`
                // let fileName = `waaq調整ページ一覧_${DateTime.local().toSeconds() * 1000}.${fileType}`
                FileUtil.downloadWithFileType(blob, uf.original_filename, fileType)
                return null
            })
            .catch(err => {
                Logger(`err.message:${err.message}, response:${Util.output(err.response)}, status:${err.response.status}`)
                NotificationControl.showErrorMessage(err)
                return err
            })
    }

    static rename(uf: UserFile) {
        let params = { file_id: uf.file_id, original_filename: uf.original_filename }
        return axios
            .put(`${Util.prefixUrl}/download_rooms/rename`, params)
            .then(res => {
                // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data
            })
            .catch(err => {
                Logger(`err.message:${err.message}, response:${Util.output(err.response)}, status:${err.response.status}`)
                NotificationControl.showErrorMessage(err)

                return null
            })
    }

    static findFileById(fileId: string, files: UserFile[]): UserFile {
        if (!files || files.length == 0) return null
        return files.find(f => f.file_id == fileId)
    }
}
