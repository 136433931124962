import UserFile from "packs/models/UserFile"
import axios from "axios"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "packs/models/Notice"
import NotificationControl from "./NotificationControl"
import { DateTime } from "luxon"

export default class FileUtil {
    static downloadFile(file: UserFile, parentId: string = null): Promise<any> {
        // let endpoint = file.is_image_file ? `download_as_image` : `download`
        let endpoint = `download`
        let params = { file_id: file.file_id, parent_room_id: parentId }
        return axios
            .request({ params, url: `${Util.prefixUrl}/file_uploaders/${endpoint}`, responseType: "blob" })
            .then(res => res.data)
            .then(blob => {
                this.download(blob, file.original_filename)
                return null
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static downloadAttachment(file: UserFile, parentId: string, publicRoomId: string = null) {
        let endpoint = `download_attachment_files`
        let params = { file_id: file.file_id, parent_room_id: parentId, public_room_id: publicRoomId }
        return axios
            .request({ params, url: `${Util.prefixUrl}/file_uploaders/${endpoint}`, responseType: "blob" })
            .then(res => res.data)
            .then(blob => {
                this.download(blob, file.original_filename)
                return null
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * RoomのCSV/Excelファイルをダウンロードしてきます。
     * @param start_date
     * @param end_date
     * @param appo_statuses
     * @param progress_status
     * @param public_room_id
     * @param file_type
     */
    static downloadRoomsFile(
        start_date: string,
        end_date: string,
        appo_statuses: number[],
        progress_status: string[],
        public_room_id: string,
        file_type: string,
        public_ids: string[], // ユーザーが入力した公開ページのID
        only_upcoming_fixed: boolean,
        sort_type: string
    ) {
        const params = {
            start_date,
            end_date,
            appo_statuses,
            progress_status,
            public_room_id,
            public_ids: public_ids,
            file_type,
            only_upcoming_fixed,
            sort_type,
        }

        return axios
            .post(`${Util.prefixUrl}/download_rooms/request`, params)
            .then(res => {
                // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)

                return null
            })
    }

    s2ab(s: string) {
        var buf = new ArrayBuffer(s.length)
        var view = new Uint8Array(buf)
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
        return buf
    }

    static downloadWithFileType(blob, csvName: string, fileType: string) {
        let link = document.createElement("a")
        //                                           application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        let type = fileType == "csv" ? "text/csv" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        if (fileType == `csv`) {
            let _blob = new Blob([blob], { type: type })
            link.href = window.URL.createObjectURL(_blob)
        } else {
            // type = "application/octet-stream"

            let _blob = new Blob([blob], {
                type: type,
            })
            // let _blob = new Blob([blob], { type: type })
            // Logger(`type of blob ${_blob}`)
            link.href = window.URL.createObjectURL(_blob)
            // link.href =
            //     "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            //     encodeURIComponent(blob)
        }

        link.download = csvName
        link.click()
    }

    static download(blob, filename: string) {
        let link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
    }
}
